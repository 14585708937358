/*
=====================================================================
*   Ceevee v1.0 Layout Stylesheet
*   url: styleshout.com
*   03-18-2014
=====================================================================

   TOC:
   a. General Styles
   b. Header Styles
   c. About Section
   d. Resume Section
   e. Portfolio Section
   f. Call To Action Section
   g. Testimonials Section
   h. Contact Section
   i. Footer

===================================================================== */

/* ------------------------------------------------------------------ */
/* a. General Styles
/* ------------------------------------------------------------------ */

body, .whiteBgAgent { background: #F5F5F0;
font-family:'alliance_no.1light', sans-serif;}
body.blackBody{ background:#000;
font-family:'alliance_no.1light', sans-serif; }
body.whiteBgAgent{
   background: #F5F5F0 !important;
}

body.blackBody,
body.blackBody p,
body.blackBody span,
body.blackBody h1,
body.blackBody h2,
body.blackBody h3,
body.blackBody h4,
body.blackBody ul li,
body.blackBody div

{ color:#FFF !important;
font-family:'alliance_no.1light', sans-serif; }

body.blackBody p,
body.blackBody ul li,
.agente-inner-wrapper p,
.agente-inner-wrapper ul li{
   font-family:'alliance_no.1light', sans-serif;
   font-size:calc(15px + 0.5vw);
   line-height: calc(20px + 0.7vw);   
}
body.blackBody .services-wrap ul li{
   margin-bottom: 0px !important;
    text-transform: uppercase;
    letter-spacing: 2.5px;   
    font-size:calc(12px + 0.1vw);
}





.agente-inner-wrapper,
.agente-inner-wrapper p,
.agente-inner-wrapper div,
.agente-inner-wrapper span,
.agente-inner-wrapper ul li,
.agente-inner-wrapper a,
.agente-inner-wrapper h1,
.agente-inner-wrapper h2,
.agente-inner-wrapper h3,
.agente-inner-wrapper h4{
   color: #000 !important;
}

.main_container{
   min-height: 100vh;
    width: 100%;
    overflow: hidden;   
}
.pinned {
    position: relative;
    width: 100%;
}
.blackFont{color: #000;}
.whiteFont{color:#FFF !important;}
b,strong{
}

body.blackBody .letsTalk{
   display: none;
}

.letsTalk{
       font-family: 'alliance_no.1light', sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    color: #000;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 30px;
}

.icon-services-col{
   position: relative;
}

.icon-services-col img{
   position: relative;
   top: 5px;
}
/* ------------------------------------------------------------------ */
/* b. Header Styles
/* ------------------------------------------------------------------ */

header {
   position: relative;
   height: 20%;
   min-height: 90px;
   width: 100%;
   background-size: cover !important;
	-webkit-background-size: cover !important;
   text-align: center;
   overflow: hidden;
}
#about-us{
   margin-top: 60px;
}
.about-inner-wrapper, .agente-inner-wrapper {
    margin-top: 60px;
}
/* vertically center banner section */
header:before {
   content: '';
   display: inline-block;
   vertical-align: middle;
   height: 100%;
}
header .banner {
   display: inline-block;
   vertical-align: middle;
   margin: 0 auto;
   width: 85%;
   padding-bottom: 30px;
   text-align: center;
}

header .banner-text { width: 100%; }
header .banner-text h1 {
   font: 90px/1.1em 'alliance_no.1light', sans-serif;
   color: #fff;
   letter-spacing: -2px;
   margin: 0 auto 18px auto;
   text-shadow: 0px 1px 3px rgba(0, 0, 0, .8);
}
header .banner-text h3 {
   font: 18px/1.9em 'alliance_no.1light', sans-serif;
   color: #A8A8A8;
   margin: 0 auto;
   width: 70%;
   text-shadow: 0px 1px 2px rgba(0, 0, 0, .5);
}
header .banner-text h3 span,
header .banner-text h3 a {
   color: #fff;
}
header .banner-text hr {
   width: 60%;
   margin: 18px auto 24px auto;
   border-color: #2F2D2E;
   border-color: rgba(150, 150, 150, .1);
}

/* header social links */
header .social {
   margin: 24px 0;
   padding: 0;
   font-size: 30px;
   text-shadow: 0px 1px 2px rgba(0, 0, 0, .8);
}
header .social li {
   display: inline-block;
   margin: 0 15px;
   padding: 0;
}
header .social li a { color: #fff; }
header .social li a:hover { color: #FF5959; }


.services-list li a:hover{background: #FF5959; color: #FFFFFF !important;}
.colabsLink:hover {background: #FF5959 !important; color: #FFFFFF !important;}


/* scrolldown link */
header .scrolldown a {
   position: absolute;
   bottom: 30px;
   left: 50%;
   margin-left: -29px;
   color: #fff;
   display: block;
   height: 42px;
   width: 42px;
   font-size: 42px;
   line-height: 42px;
   color: #fff;
   border-radius: 100%;

   -webkit-transition: all .3s ease-in-out;
   -moz-transition: all .3s ease-in-out;
   -o-transition: all .3s ease-in-out;
   transition: all .3s ease-in-out;
}
header .scrolldown a:hover { color: #11ABB0; }

.logo-digitalizadores{
   margin-bottom: 60px;
}
#about-us p,
#agente p{
   font-size:calc(16px + 0.8vw);
    line-height: calc(30px + 0.8vw);
    padding-bottom: 10%;
    color: #000;
}
#about-us p{
   padding-bottom: 15%;
}

#agente h1{
   font-family: 'alliance_no.1bold';
   font-size:calc(35px + 1.5vw);
    line-height: calc(45px + 1.5vw);
    font-weight: bold;
    margin-bottom: 25px;
}
/* primary navigation
--------------------------------------------------------------------- */
#nav-wrap ul, #nav-wrap li, #nav-wrap a {
	 margin: 0;
	 padding: 0;
	 border: none;
	 outline: none;
    color: #000;
}

/* nav-wrap */
.nav-wrap {
   font: 12px 'alliance_no.1light', sans-serif;
   width: auto;
   text-transform: uppercase;
   letter-spacing: 2.5px;
   margin: 0 auto;
   z-index: 100;
   position: fixed;
   top: 0;
}
.nav-wrap-middleMenuWrap {
   font: 12px 'alliance_no.1light', sans-serif;
   width: auto;
   text-transform: uppercase;
   letter-spacing: 2.5px;
   margin: 0 auto;
   z-index: 100;
   position: fixed;
   bottom: 5%;
   background: transparent;
}
.navPosRight{
   right: 0;
   text-align: right;
}
.navPosLeft{
   left: 0;
   text-align: left;
   z-index: 200;
}
.navPosRight ul#nav li a{
   text-align: right !important;
}

.nav{
   display: block !important;
}
.nav-medium{
   top:  40% !important;
}
.opaque { background-color: #333; }

/* hide toggle button */
.nav-wrap > a.mobile-btn { display: none; }

ul#nav {
   min-height: 48px;
   width: auto;

   /* center align the menu */
   text-align: center;
   background: transparent;
   margin-top: 40px;
}
ul#nav li {
   position: relative;
   list-style: none;
   height: 15px;
   display: block;
   background: transparent;
   margin-bottom: 15px;
}

/* Links */
ul#nav li a {
background: transparent;
/* 8px padding top + 8px padding bottom + 32px line-height = 48px */

   display: block;
   padding: 8px 13px;
   line-height: 0;
	text-decoration: none;
   text-align: left;
   color: #000;

	-webkit-transition: color .2s ease-in-out;
	-moz-transition: color .2s ease-in-out;
	-o-transition: color .2s ease-in-out;
	-ms-transition: color .2s ease-in-out;
	transition: color .2s ease-in-out;
   font-family: 'alliance_no.1bold';
}


.agente-inner-wrapper ul#nav li a {
   color: #000 !important;
}

ul#nav li a:active { background-color: transparent !important; }
ul#nav li.current a { color: #F06000; }

.middleMenuWrap{
   position: absolute;
    height: 20%;
    min-height: 150px;
    width: 100%;
    background-size: cover !important;
    -webkit-background-size: cover !important;
    text-align: center;
    overflow: hidden;  
}

/* ------------------------------------------------------------------ */
/* c. About Section
/* ------------------------------------------------------------------ */
.maxWidth8{
   max-width:  1390px !important;
   margin:  0 auto;
   padding: 0 5%;
}
#about {
   background: #2B2B2B;
   padding-top: 96px;
   padding-bottom: 66px;
   overflow: hidden;
}

#about a, #about a:visited  { color: #FF5959; }
#about a:hover, #about a:focus { color: #FF5959; }

#about h2 {
   font: 22px/30px 'alliance_no.1light', sans-serif;
   color: #fff;
   margin-bottom: 12px;
}
#about p {
   line-height: 30px;
   color: #7A7A7A;
}
#about .profile-pic {
   position: relative;
   width: 120px;
   height: 120px;
   border-radius: 100%;
}
#about .contact-details { width: 41.66667%; }
#about .download {
   width: 58.33333%;
   padding-top: 6px;
}
#about .main-col { padding-right: 5%; }
#about .download .button {
   margin-top: 6px;
   background: #444;
}
#about .download .button:hover {
   background: #fff;
   color: #2B2B2B;
}
#about .download .button i {
   margin-right: 15px;
   font-size: 20px;
}

#about-us .col-md-8{
   padding-left: 0px !important;
   padding-right: 0px !important;
}
#works .portfolio-wrapper{
   padding-left: 20px !important;
   padding-right: 20px !important;
}
.bk-contact-area .container{
   padding-left: 20px !important;
   padding-right: 20px !important;
}
/* ------------------------------------------------------------------ */
/* d. WORKS Section
/* ------------------------------------------------------------------ */

.works-wrapper{
   max-width: 1290px !important;
    margin: 0 auto;   
}

.thumb img{
}
.thumb img{
}


.grid-item:nth-child(4) .thumb img{
}
.grid-item:nth-child(5) .thumb img{
}
.thumb{
   overflow:hidden;
   line-height: 0;
}
#works{
   margin-bottom: 200px;
}

.works-detail-wrap img{
   width: 100%;
}

/* ------------------------------------------------------------------ */
/* d. Contact Section
/* ------------------------------------------------------------------ */

.contactWrap{
   max-width: 1290px !important;
    margin: 0 auto;   
}
/* ------------------------------------------------------------------ */
/* d. About Inner
/* ------------------------------------------------------------------ */
.langLink{
   cursor: pointer;
}
.langWarn{
   display: none;
}
.closeWarn{
   cursor: pointer;
}
.about-inner-wrapper{
   background: #000000;
}
.whiteBgAgent .about-inner-wrapper{
   background: #F5F5F0 !important;
}
.border-white{
   border-top: 1px solid #FFF;
   padding: 2% 0 4% 0;
}
.border-black{
   border-top: 1px solid #000;
   padding: 3% 0 4% 0;
}
.no-border-top{
   border-top: none !important;
}
.no-border-bottom{
   border-bottom: none !important;
}
.about-inner-wrapper h2{
   font-family:'alliance_no.1light', sans-serif;
   font-size:calc(20px + 0.9vw);
   line-height: calc(22px + 0.7vw);   

}
.agente-inner-wrapper h2 {
    font-family: 'alliance_no.1light', sans-serif;
    font-size: calc(24px + 0.3vw);
    line-height: calc(32px + 0.8vw);
    padding-right: calc(18% - 0.2vw);
}
.agente-inner-wrapper h3{
    font-size: calc(15px + 0.5vw);
    line-height: calc(20px + 0.7vw);
    font-weight: bold;
}
.team-content h4,
.team-content h3{
   font-size:calc(15px + 0.5vw);
   line-height: calc(20px + 0.8vw);  
   margin-bottom: 0;
}

.team-content h4{
   margin-bottom: 4%;
}
.team-img{
   margin-bottom: 5%;
}

/* ------------------------------------------------------------------ */
/* d. Contact Form
/* ------------------------------------------------------------------ */

.agente-form .contactForm{ 
       padding-right: 20px;
    padding-left: 20px;
}

.contactForm input{
      width: 100%;
    background: transparent;
    border-bottom: 1px solid #fff;
    padding-left: 0;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 12px;
    margin: 0 0 30px 0;
    padding: 0 0 8px 0;
    border-left: 0;
    border-right: none;
    border-top: none;
    line-height: inherit;


}
.contactForm input::placeholder, .contactForm textarea::placeholder{
   color: #fff;
}
.contactForm button{
    text-transform: uppercase;
    letter-spacing: 3px;
    font-family: 'alliance_no.1light', sans-serif;
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    width: auto;
    position: absolute;
    right: 0;
    bottom: 44px;
    background: #000;
    padding-left: 20px;

}
.contactForm textarea{
    width: 100%;
    border: none;
    background: url('pattern.png');
    background-repeat: repeat-x;
    margin-top: 35px;
    background-position: 0;
    line-height: 56px;
    padding: 0px 0px 89px 0px;
    min-height: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 3px;
    font-family: 'alliance_no.1light', sans-serif;
    border:none;
}

.textarea-wrap{
   overflow: hidden;
}


.contact-form{
   padding: 0 10px;
}
a:hover{
   color: #FF5959;
}

.team-content p a:hover, .col-12 a:hover, ul li a:hover, button:hover {
   color: #FF5959;
}
.bk-contact-area textarea, .agente-form .contactForm textarea{
   background-image: url('pattern2.png');
}
.bk-contact-area .contactForm input::placeholder, 
.bk-contact-area .contactForm textarea::placeholder, 
.agente-form .contactForm input::placeholder,
.agente-form .contactForm textarea::placeholder{
   color: #000;
}
.bk-contact-area .contactForm input,
.agente-form .contactForm input{
   border-bottom: 1px solid #000;
}
.bk-contact-area .contactForm button,
.agente-form .contactForm button{
    background: #F5F5F0;
    color: #000;}


.noBorder{
   border: none !important;
   padding: 2% 0 4% 0;
}

.howWrap img{
   width: calc(100px + 0.5vw);
   position: relative;
       margin-left: -10px;
}

.howWrap .col-12{
   position: relative;
}
.howWrap{
   font-size: calc(100px + 0.5vw);
   line-height: calc(100px + 0.5vw);
}


.howWrap h2{
   margin-bottom: 60px;
}
.footerImg{
   width: auto !important;

}

.agente-inner-wrapper{
  scroll-behavior: smooth !important;
  height: 100%;

}

/* ------------------------------------------------------------------ */
/* d. WORKS
/* ------------------------------------------------------------------ */

.works-detail-wrap{
   color: #000;
}
.works-detail-wrap p, .services-list{
   line-height: calc(30px + 0.6vw);
   font-size:calc(14px + 0.8vw); 
}
.services-list li{
   line-height: calc(18px + 0.6vw);
}
.works-detail-wrap p{
   line-height: calc(30px + 0.6vw);
   font-size:calc(14px + 0.8vw);  
}
.works-detail-wrap h1{
    font: 13px 'alliance_no.1light', sans-serif;
    width: auto;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    margin: 0 auto;
    z-index: 100;
    position: fixed;
    top: 0;
    margin-top: 40px;
    text-align: center;
   width: 100%;
   cursor: pointer;
}
.works-detail-wrap h1 a{
   color: #000;
}

.vertical-logo{
   position: fixed;
   z-index: 100;
   top:0;
   width: 100%;
}
.vertical-logo ul{
    right: 0;
    margin-top: 140px;   
}
.vertical-logo ul li{
   line-height: calc(40px + 0.6vw);
   font-size:calc(25px + 1.5vw);  
   padding: 8px 13px;
   
}
.vertical-logo ul li a{
   color: #000;
   
   text-align: center;
}

.works-detail-wrap .row .row {
    width: auto;
    max-width: none;
    margin: 0;
}
.works-detail-wrap h2{
   font-size: 14px;
   text-transform: uppercase;
   letter-spacing: 2.5px;
}
.TwentypxTop{
   margin-top: 20px;
}
.SixtypxTop{
   margin-top: 40px;
}
.imgFullWidth{
   width: 100%;
}
img {
    max-width: 100%;
    height: auto;
    width: 100%;
}

/* ------------------------------------------------------------------ */
/* d. Footer
/* ------------------------------------------------------------------ */

.rowFooterImg{
   max-width: 1200px !important;
   margin: 0 auto !important;
}
.footerWrap{
   margin-top: 70px;
}

.noPadGeneral{
   padding: 0 ;
}
.noPadRight{
   padding-right: 0 ;
}
.noPadLeft{
   padding-left: 0 ;
}

.smallPadRight{
       padding-right: 3px;
}
.details-container{
   margin-top: 40px !important ;
}

.imageAbsoluteBottom{
   display:flex; flex-direction:column; flex:1
}
.imageAbsoluteBottom img{
   margin-top: auto;
}
.rowGridSpace{
   margin-bottom: 80px;
}
.rowDescription{
   margin: 60px auto;
}
.rowFullHeight{
   height:  100%;
   position: relative;
}

.rowGridSpaceMedium{
   margin-bottom: 10px;
}
.rowGridSpaceMedium2{
   margin-bottom: 20px;
}
.rowGridSpaceLarge{
   margin-bottom: 20px;
}
.imageAbsoluteBottomPadRight{
   padding-right: 10px;
}
.rowRowMargin10{
   margin: 0 -10px !important;
}

.langWarn{
  font-size: 13px !important;
    line-height: 0 !important; 
}

.cookiesText{
       font-family: 'alliance_no.1light', sans-serif;
       margin: 0;
       font-size: 16px;
       letter-spacing: 1px;
       padding-left: 20px ;
}
.cookiesText a{
       font-family: 'alliance_no.1light', sans-serif;
       color: #fff;
       text-decoration: underline;
}
.CookieConsent button{
   background: #FFF !important;
   padding: 0 10px !important;
   color: #000 !important;
   font-family: 'alliance_no.1light', sans-serif;
}
/* ------------------------------------------------------------------ */
/* d. Accordion
/* ------------------------------------------------------------------ */


ul.accordion-list {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  list-style: none;
  margin: 0 0 30px 0;
}
  
ul.accordion-list li {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto 15px auto;

    cursor: pointer;
      font-family: 'alliance_no.1light', sans-serif;
    font-size: calc(15px + 0.5vw);
   

    }

ul.accordion-list li.active h3:after{
          transform: rotate(45deg);
    }
    
 ul.accordion-list li h3 {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      padding: 0 0 0 0;
      margin: 0;
      letter-spacing: 0.01em;
      cursor: pointer;
      line-height: calc(40px + 0.9vw) !important;
      font-size: calc(15px + 0.5vw);
 }
      
 ul.accordion-list li h3:after {
        content: "";
        font-family: "material-design-iconic-font";
        position: absolute;
        right: 0;
        top: 0;
        color: #Fcc110;
        transition: all 0s ease-in-out;
        font-size: 18px;
        background: url('images/open.png') no-repeat ;
        background-size: 70%;
        width: 50px;
        height: 50px;
      
    }
    
    div.answer {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
      cursor: pointer;
      border-top: 1px solid white;
   }
      
    div.answer p {
        position: relative;
        display: block;
        padding: 10px 0 0 0;
        cursor: pointer;
        margin: 0 0 15px 0;
       font-size: calc(15px + 0.5vw);
       line-height: calc(20px + 0.7vw);        
      }
   .icon-services-col img{
      width: auto !important;
   }

   .hidden{
      display: none;
   }
   input[type="search"], input[type="textarea"], input[type="text"] {
     -webkit-appearance: none;
   } 


 .privacyContent h2{
        font-size: calc(22px + 0.5vw);
       line-height: calc(35px + 0.7vw);    
 }    

 .privacyContent h1{
        font-size: calc(26px + 0.5vw);
       line-height: calc(35px + 0.7vw);    
 }  

 .privacyContent{
   padding-bottom: 80px;
 }
.services-list li a, .colabsLink, .team-content a, .services-wrap a {
    color: #FF5959 !important;
}
.team-content a, .services-wrap a {
    color: #FFF !important;
}
.team-content a:hover, .services-wrap a:hover {
    color: #FF5959 !important;
}
.services-list li a, .colabsLink{color: #FF5959 !important;}
.colabsLink:hover{color: #FFFFFF !important;}

.separadorBloques{
   border-top: 1px solid #000;
       margin: 6% 0 5% 0;
}
/* ------------------------------------------------------------------ */
/* d. Media Queries
/* ------------------------------------------------------------------ */


@media only screen and (max-width: 767px){

.contactForm .row .row {

    margin: 0 auto;
}
.row {
    margin: 0 auto;
    padding: 0 20px;
}
.agente-inner-wrapper .row {
   padding: 0 10px;
}
.agente-inner-wrapper .agente-form .row {
   padding: 0 0px;
}


.imageAbsoluteBottom{
   display:block; flex-direction:row  ; flex:auto;
}

.imageAbsoluteBottom img{
   margin-top: inherit;
}

 ul.accordion-list li h3:after {

        background-size: 53%;
        width: 50px;
        height: 50px;
      
    }
.imageAbsoluteBottomPadRight{
   padding-right: inherit;
}
 .row .row {
        margin: 0 -20px !important;
}

.contactForm  .row .row {
        margin: 0 -30px !important;
}

.rowRowMargin10{
   margin: 0 auto !important;
}
.border-white, .border-black {
    padding: 4% 0 4% 0 !important;
}
h2 {

    margin-bottom: 15px;
}

.about-inner-wrapper {
  margin-bottom: 0%; 
}

#about-us p{
   padding-right: 10px !important;
   padding-left: 10px !important;
}
#works {
    margin-bottom: 100px;
}
.contactForm{
   padding-bottom: 100px;
}

.icon-services-col img {

    width: auto;
}
.howWrap img {

}
.wrapCircleNumber{
}

.wrapCircleNumberDiv, .numberAlign{
    align-items: center!important;
    display: flex!important;
}
.numberAlign{
}
.wrapCircleNumberDiv p{
   margin-bottom: 0;
       margin-left: -5px;
}

.mobileDisplayed{
   display: block;
}
.MobileHidden{
   display: none !important;
}

.border-white .col-12, .border-black .col-12{
   padding: 0px 0px !important
}
.row.border-white, .row.border-black {
    margin: 0 auto;
    padding: 0 20px;
}

.contactForm .row.row {
       margin: 0 -30px !important;
    }

.agente-form .contactForm {
    padding-right: 0px; 
    padding-left: 0px; 
    padding-bottom: 0;
    
}
.about-inner-wrapper  .contactForm, .agente-form .contactForm {

}

.agente-inner-wrapper {
    padding-bottom: 120px;
}

.howWrap h2 {
    margin-bottom: 30px;
}
.about-inner-wrapper .contactForm .col-12 .row{
    margin: 0 0 !important;
    padding: 0;
}

.contactForm textarea{
       padding: 0px 0px 89px 20px;
}
 .agente-form .contactForm textarea {
    padding: 0px 0px 89px 0px !important;
}
 .agente-form .contactForm .row.row {
    margin: 0 0px !important;
}
.howWrap img {
    margin-left: 0;
}
 .agente-form .contactForm .col-12 {
    padding: 0 0px !important;
}
.about-inner-wrapper .contactForm .row .col-12,
.agente-form .contactForm .row .col-12{
   padding: 0 !important;
}

.scrollContainerAgente.row .row{
       margin: 0 0px !important;
}
.services-wrap{
   padding-bottom: 0 !important;
}

.agente-inner-wrapper .row.border-black{
   margin: 0 auto;
    padding: 4% 0px !important;  
}

.collabsWrap{
   padding-bottom: 80px;
}

.portfolio-wrapper .row{
   padding: 0 10px !important;
}
small, .langWarn {
    font-size: 11px !important;
    line-height: inherit;
}


.about-inner-wrapper .contactForm .row{
   padding: 0;
}
.agenteTopWrap {
   padding: 0 !important;
}
.agenteTopWrap  .col-12{
   padding: 0 !important;
}

.homepageWrap .contactForm .row .col-12{
   padding: 0 5px!important;
}
.homepageWrap .bk-contact-area .contactForm .col-12{
   padding: 0;
}
.homepageWrap .bk-contact-area .contactForm .textarea-wrap .contact-form{
   padding: 0 0px;
}
.bk-contact-area{
   padding-bottom: 100px;
}

.imageAbsoluteBottom{
   flex-direction:unset; 
   flex:unset;
   display: block;
}

.rowGridSpace {
    margin-bottom: 10px;
}
.works-detail-wrap {
    color: #000;
    padding-top: 60px;
}



}


